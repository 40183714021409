<!--
  Screen to provide fast access to each piece of portal functionality
-->
<template>
  <div>
    <b-tabs
      v-model="selectedTab"
      size="is-medium"
      type="is-toggle-rounded"
      position="is-centered"
      class="has-background-white-ter"
      style="padding-top: 1rem; padding-bottom: 1rem"
    >
      <b-tab-item
        :label="$t('dashboard-tab-omw')"
        icon="map-marked-alt"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div class="column">
            <router-link
              :class="{
                disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
              }"
              to="/omwLookup"
            >
              <div
                class="notification is-info has-background-link dashboard-card"
                :class="{
                  disabled: !purchasedOmw || !(omwLookupAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('dashboard-omw-viewer') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'map-marked-alt']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>

            <br />
            <div
              :class="{
                disabled:
                  !purchasedMarketing || !(marketingAccess || adminAccess),
              }"
            >
              <router-link
                to="/marketing"
                :class="{
                  disabled:
                    !purchasedMarketing || !(marketingAccess || adminAccess),
                }"
              >
                <div
                  class="notification is-info has-background-link dashboard-card"
                >
                  <p class="title">{{ $t('dashboard-omw-marketing') }}</p>
                  <vue-fontawesome
                    :icon="['fad', 'funnel-dollar']"
                    size="3x"
                    class="icon-layout"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedFeedback || !(feedbackAccess || adminAccess),
              }"
              to="/feedbackViewer"
            >
              <div
                class="notification is-info has-background-link dashboard-card"
                :class="{
                  disabled:
                    !purchasedFeedback || !(feedbackAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('dashboard-omw-feedback') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'comments']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
            <br />
            <router-link
              v-if="resourceConfigEnabled"
              :class="{
                disabled:
                  !purchasedOmw || !(resourceConfigAccess || adminAccess),
              }"
              to="/omwResourceConfig"
            >
              <div
                class="notification is-info has-background-link dashboard-card"
                :class="{
                  disabled:
                    !purchasedOmw || !(resourceConfigAccess || adminAccess),
                }"
              >
                <p class="title">{{ $t('dashboard-resource-config') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'user-hard-hat']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <!-- <div class="column">
            <router-link
              :class="{
                disabled:
                  !purchasedOmwStats || !(omwStatsAccess || adminAccess),
              }"
              to="/omwStats"
            >
            <router-link
              :class="{
                disabled: true,
              }"
              to="/omwStats"
            >
              <div
                class="notification is-info has-background-link dashboard-card"
                :class="{
                  disabled:
                    !purchasedOmwStats || !(omwStatsAccess || adminAccess),
                }"
              >
              <div
                class="notification is-info has-background-link dashboard-card"
                :class="{
                  disabled: true,
                }"
              >
                <p class="title is-italic">
                  {{ $t('dashboard-omw-statistics') }} coming soon!
                </p>
                <vue-fontawesome
                  :icon="['fad', 'chart-pie']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div> -->
        </div>
      </b-tab-item>
      <b-tab-item
        :label="$t('dashboard-admin-tab')"
        icon="tools"
        class="tab-item-spacing"
      >
        <div class="columns">
          <div v-if="adminAccess || customerAdminAccess" class="column">
            <router-link to="/admin">
              <div
                class="notification is-info has-background-link dashboard-card"
              >
                <p class="title">{{ $t('dashboard-user-admin') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'lock']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
          <div class="column">
            <router-link to="/supportSubmission">
              <div
                class="notification is-info has-background-link dashboard-card"
              >
                <p class="title">{{ $t('omw-support-submit-ticket') }}</p>
                <vue-fontawesome
                  :icon="['fad', 'headset']"
                  size="3x"
                  class="icon-layout"
                />
              </div>
            </router-link>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  computed: {
    ...mapGetters('storeAuth', ['userId']),
    selectedTab: {
      get() {
        return this.$store.getters['storeAuth/selectedTab'];
      },
      set(val) {
        this.setSelectedTab(val);
      },
    },
  },
  methods: {
    ...mapActions('storeAuth', ['setSelectedTab']),
  },
});
</script>

<style scoped>
.disabled-tile {
  opacity: 0.1% !important;
}

h1,
p {
  text-align: center;
}
p {
  color: red;
}
.notification a:not(.button) {
  text-decoration: none;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.icon-layout {
  display: block;
  margin: 0 auto;
}

.tab-item-spacing {
  padding-top: 1rem;
}

.dashboard-card {
  height: 12em;
}
</style>
