var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{staticClass:"has-background-white-ter",staticStyle:{"padding-top":"1rem","padding-bottom":"1rem"},attrs:{"size":"is-medium","type":"is-toggle-rounded","position":"is-centered"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('dashboard-tab-omw'),"icon":"map-marked-alt"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
            },attrs:{"to":"/omwLookup"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card",class:{
                disabled: !_vm.purchasedOmw || !(_vm.omwLookupAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('dashboard-omw-viewer')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'map-marked-alt'],"size":"3x"}})],1)]),_c('br'),_c('div',{class:{
              disabled:
                !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
            }},[_c('router-link',{class:{
                disabled:
                  !_vm.purchasedMarketing || !(_vm.marketingAccess || _vm.adminAccess),
              },attrs:{"to":"/marketing"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('dashboard-omw-marketing')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'funnel-dollar'],"size":"3x"}})],1)])],1)],1),_c('div',{staticClass:"column"},[_c('router-link',{class:{
              disabled:
                !_vm.purchasedFeedback || !(_vm.feedbackAccess || _vm.adminAccess),
            },attrs:{"to":"/feedbackViewer"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card",class:{
                disabled:
                  !_vm.purchasedFeedback || !(_vm.feedbackAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('dashboard-omw-feedback')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'comments'],"size":"3x"}})],1)]),_c('br'),(_vm.resourceConfigEnabled)?_c('router-link',{class:{
              disabled:
                !_vm.purchasedOmw || !(_vm.resourceConfigAccess || _vm.adminAccess),
            },attrs:{"to":"/omwResourceConfig"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card",class:{
                disabled:
                  !_vm.purchasedOmw || !(_vm.resourceConfigAccess || _vm.adminAccess),
              }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('dashboard-resource-config')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'user-hard-hat'],"size":"3x"}})],1)]):_vm._e()],1)])]),_c('b-tab-item',{staticClass:"tab-item-spacing",attrs:{"label":_vm.$t('dashboard-admin-tab'),"icon":"tools"}},[_c('div',{staticClass:"columns"},[(_vm.adminAccess || _vm.customerAdminAccess)?_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/admin"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('dashboard-user-admin')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'lock'],"size":"3x"}})],1)])],1):_vm._e(),_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":"/supportSubmission"}},[_c('div',{staticClass:"notification is-info has-background-link dashboard-card"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('omw-support-submit-ticket')))]),_c('vue-fontawesome',{staticClass:"icon-layout",attrs:{"icon":['fad', 'headset'],"size":"3x"}})],1)])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }